#picCycleLayer {
	position: relative;
	line-height: 0;
	@media #{$down600}	{ margin-bottom: 30px; }

	#picCycle {
		margin: 0;

		.picSlide 	{ line-height: 0; }
		img 		{ width: 100%; }
	}

	.slick-dots {
		position: absolute;
		left: -50px;
		top: 50%;
		bottom: auto;
		width: 50px;
		transform: translateY(-50%); -webkit-transform: translateY(-50%);
		@media #{$down1200} { left: -30px; width: 30px; }
		@media #{$down1024} {
			left: 0;
			right: 0;
			top: auto;
			bottom: 70px;
			width: auto;
			transform: none; -webkit-transform: none;
		}
		@media #{$down768} 	{ bottom: 60px; }

		li {
			display: block;
			position: relative;
			text-align: center;
			width: 10px;
			height: 10px;
			margin: 0 0 20px 0;
			padding: 0;

			@media #{$down1024} { display: inline-block; width: auto; margin: 0 15px 10px; }

			button {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 6px;
				height: 6px;
				padding: 0;
				background: #ccc;
				border-radius: 50%;
				transform: translate(-50%, -50%);
				transition: all 300ms;

				&::before { display: none; }
			}

			&:hover button { background: $color2; }

			&.slick-active button {
				width: 10px;
				height: 10px;
				background: $color2;
			}
		}
	}

	#soumission {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		padding: 30px 20px;
		font: 12px/20px $font1bold;
		text-transform: uppercase;
		letter-spacing: 1px;
		color: #fff;
		text-align: center;
		background: $color2;
		transition: background 300ms;
		@media #{$down1400} { padding: 20px; }
		@media #{$down768} 	{ padding: 15px; }

		&:hover { background: $color1; }
		img 	{ vertical-align: middle; margin-right: 20px; }
	}
}
footer {
	@media #{$down600} {
		text-align: center;
	}

	> div:first-child {
		padding-top: 70px;
		padding-bottom: 60px;

		@media #{$down1024} {
			padding-top: 60px;
			padding-bottom: 50px;
		}

		@media #{$down600} {
			padding-top: 50px;
			padding-bottom: 40px;
		}
	}

	#leftCol {
		float: left;
		@media #{$down600} { float: none; margin-bottom: 40px; }

		p 	{
			margin-bottom: 50px;
			@media #{$down900} { margin-bottom: 32px; }
		}

		h3 	{
			float: left;
			margin: 0 50px 0 0;
			font-size: 30px;
			line-height: 56px;
			@media #{$down900} { margin-right: 0; }
			@media #{$down600} { float: none; line-height:45px; font-size: 18px; }

			a {
				color: #fff;
				cursor: default;
			}
		}

		.link {
			float: left;
			margin-top: 10px;

			@media #{$down900} {
				display: block;
				clear: both;
			}
			@media #{$down600} {
				float: none;
				width: 125px;
				margin: 5px auto 0 auto;
			}
		}

		.logo {
			width: 15.5rem;
			height: 4rem;
			fill: $color2;
		}
	}

	#rightCol {
		float: right;
		width: 420px;
		padding-top: 100px;
		text-align: right;
		@media #{$down1024} { width: 350px; }
		@media #{$down768}	{ width: 300px; max-width: 100%; text-align: left;}
		@media #{$down600} 	{ float: none; margin: 0 auto; padding-top: 30px; text-align: center; }

		h6 	{
			margin-bottom: 30px;
			@media #{$down600} { margin-bottom: 20px; }
		}

		p 	{
			font-size: 13px;
			color: #fff;
			text-align: right;
			margin: 0;
			@media #{$down600} 	{ text-align: center; }

			a {
				transition: opacity 300ms;

				&:hover { opacity: 0.5; }
				img		{ margin-left: 15px; }
			}
		}

		#infolettreForm {
			width: 100%;
			@media #{$down900} { margin-bottom: 75px; }
			@media #{$down600} {
				width: calc(100% - 25px);
				max-width: calc(100% - 25px);
				margin: 0 0 45px 25px;
			}

			.input-field {
				position: relative;
				padding-right: 50px;
				margin: 5px 0 0 0;

				input {
					width: 100%;
					height: 35px;
					font: 15px/35px $font1reg;
					border-color: $color2;
					color: #fff;

					&:focus {
						border-color: rgba(#fff, 0.5);
					}

					&.valid, &.error {
						border-color: $color2;
					}
				}

				label {
					font: 15px/35px $font1reg;
					color: rgba(#fff, 0.5);
				}

				button {
					position: absolute;
					right: 0;
					bottom: 0;
					height: 40px;
					width: 40px;
					font-size: 10px;
					line-height: 35px;
					text-align: center;
					border: none;
					background: $color2;
					cursor: pointer;
					border-radius: 50%;
					transition: background 300ms;

					.icon{
						width: 1em;
						height: 1em;
					}

					.icon-stroke {
						stroke: #fff;
						stroke-width: 3px;
						vertical-align: middle;
						transform: rotate(180deg); -webkit-transform: rotate(180deg);
					}

					&:hover {
						background: #fff;
						.icon-stroke { stroke: $color2; }
					}
				}

				input:focus + label,
				input.valid + label,
				input.error + label {
					top: -12px;
					font-size: 11px;
					line-height: 12px;
				}
			}
		}
	}

	#copy {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 30px;
		padding-bottom: 25px;
		background: rgba(#000, 0.2);

		@media #{$down600} {
			flex-direction: column;
			padding-top: 20px;
			padding-bottom: 15px;
		}

		.left {
			display: flex;
			align-items: center;

			@media #{$down600} {
				flex-direction: column;
				margin-bottom: 12px;
			}

			p:nth-child(1) {
				margin-right: 25px;

				@media #{$down600} {
					margin-right: 0;
					margin-bottom: 12px;
				}
			}
		}

		p {
			margin: 0;
			font-size: 12px;
			line-height: 16px;
			color: rgba(#fff, 0.5);

			@media #{$down600} {
				text-align: center;
			}

			a {
				transition: color 300ms;

				&:hover {
					color: #fff;
				}
			}
		}
	}
}

//Z-Index
//Header 200
//Menu 150

html 	{ font: 12px/1 $font1reg; color: $color1; }

// STYLES __________
h1	{
	margin: 0;
	font: 175px/1 $font1bold;
	color: $color3;
	@media #{$down1400} { font-size: 140px; }
	@media #{$down1200} { font-size: 120px; }
	@media #{$down1024} { font-size: 100px; }
	@media #{$down768} 	{ font-size: 80px; }
	@media #{$down600} 	{ font-size: 70px; }
	@media #{$down500} 	{ font-size: 60px; }
}

h2	{
	margin: 0 0 50px 0;
	font: 50px/1.1 $font1bold;
	color: $color1;
	@media #{$down1600} { font-size: 45px; }
	@media #{$down1400} { font-size: 40px; }
	@media #{$down1200} { margin-bottom: 40px; font-size: 36px; }
	@media #{$down768} 	{ margin-bottom: 30px; font-size: 32px; }
	@media #{$down600} 	{ font-size: 30px; }
	@media #{$down500} 	{ font-size: 26px; }
	@media #{$down400} 	{ font-size: 24px; }
}

h3	{
	margin: 0 0 25px 0;
	font: 30px/1.2 $font1bold;
	color: $color1;
	@media #{$down1200} { font-size: 28px; }
	@media #{$down1024} { font-size: 24px; }
	@media #{$down768} 	{ font-size: 22px; }
	@media #{$down600} 	{ margin-bottom: 20px; font-size: 20px; }
}


h4	{
	margin: 0 0 25px 0;
	font: 25px/1.2 $font1bold;
	color: #fff;
	@media #{$down768} 	{ font-size: 22px; }
}

h5	{
	margin: 0 0 5px 0;
	font: 22px/1.1 $font1bold;
	color: $color1;
	@media #{$down1400} { font-size: 20px; }
	@media #{$down1400} { font-size: 18px; }
}

h6	{
	margin: 0 0 20px 0;
	font: 12px/16px $font1reg;
	color: $color2;
	text-transform: uppercase;
	@media #{$down600} 	{ margin-bottom: 10px; }

	&.white{
		color: #fff;
	}
}

p {
	margin: 0 0 25px 0;
	font: 17px/1.6 $font1reg;
	color: $color1;
	@media #{$down768} 	{ margin-bottom: 20px; }
	@media #{$down600} 	{ font-size: 16px; }

	&.intro {
		margin: 0; 
		font: 25px/1.6 $font1reg;
		@media #{$down1600} { font-size: 22px; }
		@media #{$down1200} { font-size: 20px; }
		@media #{$down1024} { font-size: 18px; }
		@media #{$down600}  { font-size: 17px; }
	}
}

a {
	cursor: pointer;
}

// Le lien
.link {
	position: relative;
	display: inline-block;
	font: 13px/20px $font1bold;
	color: $color1;
	transition: color 300ms;

	&:after, &:before {
		position: absolute;
		display: inline-block;
		top: 8px;
		left: 100%;
		height: 2px;
		margin-left: 20px;
		content: '';
		line-height: 2px;
		background: $color1;
		vertical-align: middle;
		transition: width 300ms;
	}

	&:before {
		width: 30px;
	}

	&:after {
		background: $color2;
		width: 0px;
	}

	&.whiteLink {
		color: #fff;
		&:before { background: #fff; }
	}

	&:hover {
		color: $color2;
		&::after {
			width: 30px;
		}


		&.whiteLink {
			color: $color2;
			&::after { background: $color2; }
		}
	}
}

// Le bouton
.btn {
	display: inline-block;
	padding: 20px 40px 16px 40px;
	font: 13px/20px $font1bold;
	color: #fff;
	background: $color2;
	border-radius: 28px;
	transition: background 300ms, color 300ms;
	border: none;
	cursor: pointer;

	@media #{$down1024} { padding: 18px 30px 14px 30px; border-radius: 26px; }
	@media #{$down600} 	{ padding: 16px 26px 12px 26px; border-radius: 24px; }

	&:hover { background: $color1; }

	&.whiteBtn {
		color: $color2;
		background: #fff;

		&:hover { color: #fff; background: $color2; }
	}

	&.toWhite:hover { color: $color2; background: #fff; }
}

// Les icones
.icon {
	width: 1.2em;
	height: 1.2em;
	vertical-align: -0.3em;
	fill: #fff;
	stroke: none;
	transition: all 300ms;

	&.icon-stroke {
		fill: none;
		stroke: #fff;
		stroke-width: 2px;
	}
}

// Framset
.texture 	{ background: url('../../assets/images/content/background.jpg') repeat center center; }
.bg-red		{ background: $color2; }
.bg-white	{ background: #fff; }

// 404
.pageerror {
	h4 	{ margin-bottom: 10px; color: $color1; }
	p	{ 
		margin-bottom: 50px; 
		@media #{$down1200} { margin-bottom: 40px; }
		@media #{$down768} 	{ margin-bottom: 30px; }
	}
}

// Overlay
#overlay, .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#000, 0);
    visibility: hidden;
	z-index: 9;
    transition: background 500ms ease, visibility 0ms ease 300ms, z-index 0ms ease 700ms;
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#000, 0);
    visibility: hidden;
	z-index: 9;
    transition: background 500ms ease, visibility 0ms ease 300ms, z-index 0ms ease 700ms;
}























#fixedNav {
    position: fixed;
    bottom: 10%;
    right: 0;
    z-index: 15;
    transition: opacity 200ms;

    &.getAway {
        opacity: 0;
        visibility: hidden;
        transition: visibility 0ms ease 200ms, opacity 200ms;
    }

    .wrapper {
        box-shadow: -10px 0px 25px 0px rgba(0,0,0,0.1);
        transition: opacity 200ms;

        &.getAway {
            opacity: 0;
            visibility: hidden;
            transition: visibility 0ms ease 200ms, opacity 200ms;
        }
    }

    a {
        display: block;
        position: relative;
        width: 80px;
        height: 80px;
        background: #fff;
        transition: background 300ms;

        @media #{$down1024} { width: 50px; height: 50px;}

        svg {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        svg {
            position: absolute;
            width: 18px !important;
            height: 18px !important;
            margin-left: 0px !important;

            @media #{$down1024} { width: 12px; height: 12px;}

            use {
                fill: $color2 !important;
                transition: fill 300ms;
            }
        }

        &.scrollUp {
            border-bottom: 1px solid rgba($color1, 0.1);

            svg {
                width: 16px;
                height: 16px;
                transform: translate(-50%, -50%) rotate(90deg);

                @media #{$down1024} { width: 12px; height: 12px;}
            }
        }

        &:hover {
            background: $color2;

            svg { use { fill: #fff !important; }}
        }
    }
}

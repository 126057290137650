$slick-font-family: Arial;
@import '../node_modules/slick-carousel/slick/slick-theme.scss';
@import '../node_modules/slick-carousel/slick/slick.scss';

// IMPORTATION __________
// Général
@import 'essentials/variables.scss';
@import 'essentials/resets.scss';
@import 'essentials/grille.scss';
@import 'essentials/fonts.scss';
@import 'essentials/helper.scss';
@import 'essentials/base.scss';

// Components
@import 'components/hamburgerBtn.scss';
@import 'components/tiroirs.scss';

// Animation
@import 'components/animation/scrollFire.scss';
@import 'components/animation/transition.scss';

// Formulaire
@import 'components/formulaire/formulaire.scss';
@import 'components/formulaire/input.scss';
@import 'components/formulaire/textarea.scss';
@import 'components/formulaire/select.scss';

// Partials
@import 'partials/dynamic.scss';
@import 'partials/header.scss';
@import 'partials/prefooter.scss';
@import 'partials/footer.scss';
@import 'partials/menu.scss';
@import 'partials/overlays/dropDown.scss';
@import 'partials/overlays/share.scss';
@import 'partials/pagetitle.scss';

// Blocs
@import 'partials/blocs/quality.scss';
@import 'partials/blocs/pic-cycle.scss';
@import 'partials/blocs/fixedNav.scss';

//Publications
@import 'partials/publications/main-new.scss';

// Pages
@import 'pages/home.scss';
@import 'pages/news.scss';
@import 'pages/publication.scss';
@import 'pages/innovation.scss';
@import 'pages/produits.scss';
@import 'pages/remorques.scss';
@import 'pages/contact.scss';
@import 'pages/distributeur.scss';
@import 'pages/dimension.scss';
@import 'pages/politiqueDeGarantie.scss';

#filtersBar .col24 {
	padding-left: 80px;
	margin-bottom: 60px;
	background: $color2;
	@media #{$down1600} { padding-left: 50px; padding-right: 50px; }
	@media #{$down1400} { padding: 33px 20px 27px 20px; }
	@media #{$down1200} { margin-bottom: 50px; padding: 32px 20px 28px 20px; }
	@media #{$down1024} { margin-bottom: 40px; }
	@media #{$down768}	{ margin-bottom: 30px; }
	@media #{$down600} 	{ padding: 27px 20px 23px 20px; }

	h4 {
		margin: 0;
		font-size: 22px;
		line-height: 1;
		color: #fff;
		@media #{$down1400} { font-size: 20px; }
		@media #{$down1400} { font-size: 18px; }
	}

	div {
		p 	{
			margin: 0 0 10px 0;
			font-size: 12px;
			color: #fff;
		}

		h4 	{
			margin: 0;
			color: $color1;
			@media #{$down1024} 	{ font-size: 22px; }
		}

		ul li { display: inline-block; }

		&:first-child {
			padding: 40px 0 30px 0;
			@media #{$down1600} { padding: 35px 0 25px 0; }
			@media #{$down600}  { padding: 25px 0 15px 0; }

			li:first-child { margin-right: 50px; }
		}

		&:last-child {
			li .tags {
				margin: 0 0 10px 0;
				padding: 10px 20px 5px 20px;
				font-size: 15px;
				line-height: 21px;
				color: #fff;
				border: solid 1px #d4d4d4;
				margin-right: 15px;
				border-radius: 18px;

				&:last-child { margin-right: 0; }
			}
		}
	}

	.btn{
		position: absolute;
		right: 12%;
		margin-top: 4px;
	}

	.whiteBtn:hover{
		color: #fff;
		background: #ba302a;
	}
}

#productsList {
	text-align: center;

	#productsListWrapper {
		margin-left: -40px;
		width: calc(100% + 80px);
		text-align: left;
		@media #{$down1600} { margin-left: -30px; width: calc(100% + 60px); }
		@media #{$down1400} { margin-left: -25px; width: calc(100% + 50px); }
		@media #{$down1200} { margin-left: -20px; width: calc(100% + 40px); }
		@media #{$down1024} { margin-left: -15px; width: calc(100% + 30px); }
		@media #{$down768} 	{ margin-left: -10px; width: calc(100% + 20px); }
		@media #{$down500} 	{ margin-left: -5px; width: calc(100% + 10px); }

		a {
			float: left;
			display: block;
			width: 33.33%;
			padding: 0 40px;
			margin: 0 0 60px 0;
			&:nth-child(3n + 1) { clear: left; }
			@media #{$down1600} { padding: 0 30px; }
			@media #{$down1400} { padding: 0 25px; margin-bottom: 50px; }
			@media #{$down1200} { padding: 0 20px; margin-bottom: 40px; }
			@media #{$down1024} { padding: 0 15px; margin-bottom: 30px; }
			@media #{$down768}  { padding: 0 10px; margin-bottom: 20px; }
			@media #{$down600}  {
				width: 50%;
				&:nth-child(3n + 1) { clear: none; }
				&:nth-child(odd) 	{ clear: left; }
			}
			@media #{$down500}  { padding: 0 5px; margin-bottom: 10px; }

			.itemPic {
				line-height: 0;
				margin-bottom: 30px;
				overflow: hidden;
				img { width: 100%; transition: transform 300ms; -webkit-transition: -webkit-transform 300ms; }
			}

			h4 { color: $color1; }

			h5 {
				@media #{$down600} 	{ font-size: 14px; }
			}

			h6 {
				@media #{$down600} 	{ font: 10px/12px $font1reg; }
				br { display: none; }
			}

			.link {
				@media #{$down600} 	{ display: none; }
			}

			&:hover {

				.itemPic img { transform: scale(1.05); -webkit-transform: scale(1.05); }

				.link {
					color: $color2;
					&::after { width: 30px; }
				}
			}
		}
	}

	&.texture {
		h2 { margin-right: auto; margin-left: auto; max-width: 600px; color: #fff; }

		#productsListWrapper a {
			h5 { color: #fff; }

			@media #{$down600}  {
				&:nth-child(3n) { display: none; }
			}
		}
	}
}

//Open
#product #productsListWrapper > a:last-child {
	@media #{$down600} 	{ display: none; }
}

#itemDesc {
	padding-top: 40px;
	@media #{$down1400} { padding-top: 20px; }
	@media #{$down1024} { padding-top: 0; padding-left: 0; }

	h2 {
		margin-bottom: 10px;
		color: $color2;
		@media #{$down768} 	{ font-size: 28px; }
		@media #{$down600} 	{ font-size: 30px; }
		@media #{$down500} 	{ font-size: 26px; }
		@media #{$down400} 	{ font-size: 24px; }
	}

	h3 {
		margin-bottom: 50px;
		font: 25px/1.1 $font1reg;
		color: $color3;
		@media #{$down1600} { margin-bottom: 40px; font-size: 22px; }
		@media #{$down1400} { margin-bottom: 30px; font-size: 20px; }
		@media #{$down768} 	{ margin-bottom: 20px; font-size: 18px; }
		@media #{$down600} 	{ font-size: 16px; }

		span{ font: 25px/1.1 $font1bold; }
	}

	.intro {
		margin-bottom: 50px;
		@media #{$down1400} { margin-bottom: 40px; }
		@media #{$down768}	{ margin-bottom: 30px; }
        @media screen and (max-width: 630px) {
            padding-top: 1.5em;
        }
	}


	.tiroirs{

		margin-top: 45px;

		h2{ color: $color1; }

		div{
			padding-left: 50px;
			padding-bottom: 50px;

			@media #{$down1600} { padding-left: 40px; }
			@media #{$down600}  { padding-left: 20px; }

			span{
				font: 17px/1.6 $font1bold;
			}

			p{
				margin-bottom: 0px;

				&:nth-child(2n){
					margin-bottom: 15px;
				}
			}

			.mesure{
				padding-top: 25px;
			}

			.italic {
				font-style: italic;
			}

			a{
				margin-top: 15px;
			}

			ul li { display: inline-block; }

			li{
				&:first-child{
					width: 225px;
					margin-top: 25px;
					@media #{$down1600} { width: 160px; }
					@media #{$down1024} { width: 150px; }
					@media #{$down768} 	{ width: 145px; }
				}
			}
		}
	}

	#itemInfos {
		border-top: solid 1px #d4d4d4;
		border-bottom: solid 1px #d4d4d4;

		div {

			p 	{
				margin: 0 0 10px 0;
				font-size: 12px;
				color: $color3;
			}

			h4 	{
				margin: 0;
				color: $color1;
				@media #{$down1024} 	{ font-size: 22px; }
			}


			ul li { display: inline-block; }

			&:first-child {
				padding: 40px 0 30px 0;
				border-bottom: solid 1px #d4d4d4;
				@media #{$down1600} { padding: 35px 0 25px 0; }
				@media #{$down600}  { padding: 25px 0 15px 0; }

				li:first-child {
					margin-right: 50px;
					@media #{$down1400}  { margin-bottom: 20px; }
				}
			}

			&:last-child {
				padding: 35px 0 25px 0;
				@media #{$down1600} { padding: 30px 0 20px 0; }
				@media #{$down600}  { padding: 20px 0 10px 0; }

				li {
					margin: 0 0 10px 0;
					padding: 10px 20px 5px 20px;
					font-size: 15px;
					line-height: 21px;
					border: solid 1px #d4d4d4;
					margin-right: 15px;
					border-radius: 18px;

					&:last-child { margin-right: 0; }
				}
			}
		}
	}
}

#productDesc {
	@media #{$down1024} { padding-left: 0; padding-right: 0; }
}

#product #about {
	background: url('../../assets/images/content/about2.jpg') no-repeat center center;
	background-size: cover;
}

#form-button-container {
    position: relative;
    display: inline-block;
    float: right;
    z-index: 100;

    @media #{$down700} {
        display: none;
    }
}

#form-button, #form-button-mobile {
	display: inline-block;
	padding: 20px 40px 16px 40px;
	font: 13px/20px $font1bold;
	color: #fff;
	background: $color2;
    border-radius: 28px;
	transition: background 300ms, color 300ms;
	border: none;
	cursor: pointer;

	@media #{$down1024} { padding: 18px 30px 14px 30px; border-radius: 26px; }

	&:hover { background: $color1; }

    svg {
        content: "";
        width: 15px;
        height: 15px;
        flex-shrink: 0;
        display: inline-block;

        @media #{$down600} 	{ width: 12px; height: 12px; }
    }

    &.whiteBtn {
		color: $color2;
		background: #fff;
        margin-top: 15px;

		&:hover { color: #fff; background: $color2; }
	}
}

#form-button-mobile {
    @media #{$up700} {
        display: none;
    }
}

#form-button-container-fixed {
    position: fixed;
    width: 100%;
    height: 50px;
    bottom: 0px;
    z-index: 15;
    transition: opacity 200ms;

    &.getAway {
        opacity: 0;
        visibility: hidden;
        transition: visibility 0ms ease 200ms, opacity 200ms;
    }

    .wrapper {
        width: 100%;
        height: 100%;
        transition: opacity 200ms;

        &.getAway {
            opacity: 0;
            visibility: hidden;
            transition: visibility 0ms ease 200ms, opacity 200ms;
        }
    }

    #form-button-fixed {
        display: flex;
        width: 100%;
        height: 100%;
        font: 16px/23px $font1bold;
        color: #fff;
        background: $color2;
        transition: background 300ms, color 300ms;
        border: none;
        cursor: pointer;
        justify-content: center;
        align-items: center;

        &:hover { background: $color1; }

        svg {
            content: "";
            width: 15px;
            height: 15px;
            flex-shrink: 0;
            display: inline-block;
        }
    }

    @media #{$up700} {
        display: none;
    }

}


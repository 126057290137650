h1.lgDate {
	position: absolute;
	left: -90px;
	top: -50px;
	color: $color4;
	z-index: -1;
	@media #{$down1400} { left: -70px; }
	@media #{$down1200} { left: -40px; }
	@media #{$down1024} { left: -20px; }
	@media #{$down768}  { left: 0; top: -45px; }
	@media #{$down600}  { display: none; }

	span {
		font-size: 1.1em;
		@media #{$down600}  { font-size: 1.3em;  }
	}
}

#noNews {
	height: 300px;
	padding-top: 75px;

	h3 {
		color: $color1;
		line-height: 50px;
	}
}

#mainNews {
	@media #{$down600}  { padding-bottom: 40px; }
}

//SecNew
#secNews {
	text-align: center;
	@media #{$down600}  { padding-top: 0; }

	.secNewBloc {
		position: relative;
		display: block;
		padding: 0 8%;
		margin-bottom: 160px;
		text-align: left;
		@media #{$down1400} { padding: 0 6%;  }
		@media #{$down1200} { margin-bottom: 120px; }
		@media #{$down768} 	{
			padding: 0 40px;
			&:nth-child(odd) 	{ padding-left: 0; }
			&:nth-child(even) 	{ padding-right: 0; }
		}
		@media #{$down600}  { 
			margin-bottom: 25px;
			padding: 0;
			&:not(:last-child){
				border-bottom: 1px solid rgba(#000, 0.1);
			}
			&:first-child{
				padding-top: 30px;
				border-top: 1px solid rgba(#000, 0.1);
			}
		}
		
		&:hover .link {
			color: $color2;
			&::after { width: 30px; }
		}
	}

	.btn {
		margin-top: -30px;
		@media #{$down600}  { margin-top: 0;  }
	}
}
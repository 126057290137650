// UN CHAMP DE TEXTE __________
.input-field {
	input {
		width: 100%;
		height: 35px;
		padding: 0;
		border: none;
		border-bottom: 2px solid #686868;
		font: 16px/35px $font1reg;
		color: #fff;
		background: transparent;
		transition: 300ms ease;

		&:focus 		{ border-bottom-color: $color2; }
		&::-ms-clear 	{ display: none; }
	}

	label {
		position: absolute;
		top: 0px;
        left: 0;
		font: 16px/35px $font1reg;
		color: #686868;
		cursor: text;
		transition: 300ms $ease;
	}

	input:focus + label,
	input.valid + label,
	input.error + label {
		top: -12px;
		font-size: 11px;
		line-height: 12px;
		cursor: default;
		pointer-events: none;
	}
}
#overlay-dropDown {
    position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	overflow: hidden;
	visibility: hidden;
	z-index: 100;
    pointer-events: none;
    transition: visibility 0ms ease 700ms, opacity 300ms;

    @media screen and (max-height: 600px) { display: none; }
    @media #{$down1024} { display: none; }

    .wrapper {
        display: flex;
		position: fixed; 
        top: -560px;
        right: 0;
        left: 0;
		background: #fff;
        align-items: center;
		overflow-x: hidden;
		overflow-y: auto;
        pointer-events: all;
        z-index: 102;
        scrollbar-width: none;

        &::-webkit-scrollbar { display: none; }

        a {
            position: relative;
            float: left;
            width: 50%;
            height: 560px;

            p {
                position: absolute;
                width: 100%;
                top: 50%;
                font: 40px/1.5 $font1bold;
                color: #fff;
                text-align: center;
                z-index: 1;
                transform: translateY(-50%);

                span{
                    font: 14px/1.5 $font1bold;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba(#2a2a2a, 0.7);
                transition: background 300ms;
            }

            &:hover:before { background: rgba(#2a2a2a, 0.5); }
            &:first-child { background:	url('../images/content/menu1.jpg') no-repeat center / cover; }
            &:last-child  { background:	url('../images/content/menu2.jpg') no-repeat center / cover; }
        }
    }
}

.showOverlayDropDown {
    header {
        background: #fff;
        z-index: 2000;
    }

    #mainNav {
        z-index: 2001;
        transition: z-index 0ms;
    }

    #remorques header, #distributeur header {
        background:	url('../../assets/images/content/background.jpg') repeat center center;
    }

    #dimension header {
        background: $color2;
    }
}

.showOverlayDropDown:not(.closingOverlay) {
    #overlay-dropDown {
        overflow-y: auto;
        visibility: visible;
        transition: right 500ms ease;

        &::-webkit-scrollbar { display: none; }

        .overlay {
            background: rgba(#000, 0.3);
            visibility: visible;
            z-index: 101;
            pointer-events: all;
            transition: background 700ms $ease;
        }

        .wrapper {
            top: 80px; right: 0%; left: 0%;
            transition: top 700ms cubic-bezier(0.645, 0.045, 0.355, 1);

            @media screen and (max-height: 720px) { top: 70px; }
        }
    }
}

.showOverlayDropDown.closingOverlay #overlay-dropDown .wrapper {
    transition: top 700ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

/*** MENU ***/
#sideMenu {
	position: fixed;
	top: -100%;
	right: 0px;
	height: 100%;
	width: 100%;
	z-index: 150;
	visibility: hidden;
	overflow-x: hidden;
	transform: none; -wekbit-transform: none;
	transition: top 450ms cubic-bezier(0.215, 0.61, 0.355, 1), transform 500ms $ease, visibility 0ms ease 450ms;
	-webkit-transition: top 450ms cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 500ms $ease, visibility 0ms ease 450ms;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		height: 71px;
		left: 0;
		right: 0;
		background: $color2;
		z-index: 1;
	}

	#sideMenuContainer {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		background: $color2;

	}

	#menuLinks {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: calc(35% - 18px);
		padding: 90px 0 30px 0;
		overflow: hidden;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		@media #{$down1400} { padding-top: 70px; }
		@media #{$down1200} { right: calc(40% - 16px); }
		@media #{$down1024} { padding-top: 50px; }
		@media #{$down768} 	{
			position: relative;
			left: auto;
			top: auto;
			bottom: auto;
			right: auto;
			overflow: auto;
			padding: 40px 0 10px 10px;
		}
	}

	#menuCoord {
		position: absolute;
		left: 65%;
		right: 0;
		top: 0;
		bottom: 0;
		padding: 170px 0 30px 0;
		z-index: 1;
		background: $color2;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
		@media #{$down1400}	{ padding-top: 150px; }
		@media #{$down1200}	{ left: 60%; }
		@media #{$down1024}	{ padding-top: 130px; }
		@media #{$down768} 	{
			position: relative;
			left: auto;
			top: auto;
			bottom: auto;
			right: auto;
			width: auto;
			padding: 0 20px 20px 20px;
			overflow: visible;
		}

		.relative {
			height: 100%;
			width: 100%;

			div {
				position: absolute;
				left: 0;
				top: 0;
                display: flex;
                flex-direction: column;

				h6{
					color: #fff;
					@media #{$down1200} { margin-bottom: -10px; }
				}
				h3{
					float: left;
					margin-right: 50px;
					color: #fff;
					line-height: 56px;
					@media #{$down1200}	{ margin-bottom: 5px; float: none; }
				}
				.whiteBtn:hover{
					color: #fff;
					background: #ba302a;
				}
                .auto{
                    width: fit-content;
                }
			}
		}
	}

	.fadeUp {
		transform: translateY(20px); -webkit-transform: translateY(20px);
		transition: opacity 200ms, transform 0ms ease 200ms; -webkit-transition: opacity 200ms, -webkit-transform 0ms ease 200ms;
		opacity: 0;
	}
}

#menuLinks {
	div { display: none; }

	> ul {
		padding: 50px 0;
		@media #{$down768} 	{ padding-bottom: 10px; padding-top: 40px }

		> li {
			font: 40px/1.1 $font1bold;
			@media #{$down1400} { font-size: 35px; }
			@media #{$down1024} { font-size: 32px; }
		    @media #{$down768} 	{ font-size: 30px; }
			@media #{$down600} 	{ font-size: 26px; }
			@media #{$down500} 	{ font-size: 24px; }

			a {
				display: block;
				padding: 20px 120px;
				color: $color4;
				transition: color 400ms;
				@media #{$down1400}	{ padding: 18px 80px 18px 100px; }
				@media #{$down1024}	{ padding: 14px 50px 14px 50px; }

				@media #{$down768}	{ padding: 12px 20px 12px 20px; }
				@media #{$down600}	{ padding: 10px 20px 10px 20px; }
				@media #{$down500}	{ padding: 10px; }

				&:hover { color: #ba302a; }
			}

			> a {
				position: relative;

				.icon-stroke {
					margin-left: 10px;
					width: 0.5em;
					height: 0.5em;
					vertical-align: middle;
					stroke: $color4;
					transform: rotate(-90deg); -webkit-transform: rotate(-90deg);
				}

				&:hover .icon-stroke { stroke: #ba302a; }
			}

			> div > ul {
				padding: 0 0 25px 0;
				opacity: 0;
				transform: translateY(20px); -webkit-transform: translateY(20px);
				transition: opacity 400ms, transform 0ms ease 400ms; -webkit-transition: opacity 400ms, -webkit-transform 0ms ease 400ms;
				@media #{$down1024}	{ padding-bottom: 20px; }

				> li {
					font-size: 28px;
					font-family: $font1reg;
					@media #{$down1400} { font-size: 24px; }
					@media #{$down1024} { font-size: 22px; }
					@media #{$down768} 	{ font-size: 20px; }

					> a {
						padding-top: 12px;
						padding-bottom: 12px;
						color: $color4;
						&:hover { color: #ba302a; }
						@media #{$down1024} {
							padding-top: 10px;
							padding-bottom: 10px;
						}
					}
				}
			}
		}
	}

	.sub.open {
		> a {
			color: $color4;
			.icon-stroke {
				stroke: $color4;
				transform: rotate(-180deg); -webkit-transform: rotate(-180deg);
			}
		}

		> div > ul 	{
			opacity: 1;
			transform: translateX(0); -webkit-transform: translateX(0);
			transition: opacity 400ms ease 100ms, transform 400ms ease 100ms; -webkit-transition: opacity 400ms ease 100ms, -webkit-transform 400ms ease 100ms;
		}
	}
}

/*** Showing sideMenu ***/
.show-sideMenu {
	height: 100%;
	overflow-y: scroll;
	body 		{ height: 100%; overflow: hidden; }
	#mainNav	{ z-index: 149; transition: none; }
	#sideMenu 	{
		visibility: visible;
		top: 0;
		transition: top 550ms cubic-bezier(0.19, 1, 0.22, 1), transform 500ms $ease;
		-webkit-transition: top 550ms cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 500ms $ease;

		.fadeUp {
			opacity: 1;
			transform: translateY(0); -webkit-transform: translateY(0);
			transition: opacity 400ms ease 400ms, transform 400ms ease 400ms; -webkit-transition: opacity 400ms ease 400ms, -webkit-transform 400ms ease 400ms;
		}
	}
	header {
		#logo .icon { fill: #fff; }
		div a {
			color: #fff;
			&:hover { color: $color1; }
		}
	}
}

#overlay-share {
    position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	overflow: hidden;
	visibility: hidden;
	z-index: 2001;
    pointer-events: none;
    transition: visibility 0ms ease 700ms;

    .close {
        position: absolute;
        top: 24px;
        right: 24px;
        transform: none;

        svg {
            width: 20px;
            height: 20px;

            &:hover{
                use{ fill: $color2; }
            }
        }
    }

	.wrapper {
        position: fixed;
        padding: 80px 80px;
        top: 50%;
        left: 50%;
		background: #fff;
        text-align: center;
        z-index: 100;
        opacity: 0;
        transform: translate(-50%, -50%);
		transition: opacity 500ms ease 100ms, transform 500ms ease 100ms;


        @media #{$down1024} { padding: 50px; }
        @media #{$down600} {
            width: auto;
            height: 250px;
            right: 20px;
            left: 20px;
            padding: 50px 20px;
            transform: translateY(-50%);
        }

        h2 {
            line-height: 40px;
            margin-bottom: 25px; 
            width: 350px;
            font-size: 30px;

            @media #{$down600} {
                font-size: 25px;
                margin-bottom: 15px;
                width: auto;
            }
        }

        ul {
            position: relative;
            margin-top: 16px;

            li {
                display: inline-block;

                &:not(:last-child) {
                    margin-right: 20px;

                    @media #{$down600} { margin-right: 10px; }
                }

                a {
                    display: block;
                    position: relative;
                    width: 45px;
                    height: 45px;
                    background: $color2;
                    border-radius: 50%;
                    transition: background 300ms;

                    svg {
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        top: 50%;
                        left: 50%;
                        fill: #fff;
                        transform: translate(-50%, -50%);

                        use {
                            fill: #fff;
                        }
                    }

                    &:hover {
                        background: $color1;
                    }
                }
            }
        }
	}
}

.showOverlayShare {
    #overlay {
        background: rgba(#000, 0.5);
        visibility: visible;
        z-index: 2000;
        transition: background 700ms ease;
    }

    #overlay-share {
        overflow-y: auto;
        visibility: visible;
        transition: visibility 0ms;
        pointer-events: all;

        &::-webkit-scrollbar { display: none; }

        .wrapper {
            opacity: 1;
            transform: translate(-50%, -50%);

            @media #{$down600} { transform: translateY(-50%); }

            .step {
                opacity: 1;
                transform: none;
    			transition: transform 300ms ease 300ms, opacity 300ms ease 300ms;
            }
        }
    }
}

#prefooter {
	text-align: center;
	height: 650px;
	line-height: 650px;
	@media #{$down1600} { height: 600px; line-height: 600px; }
	@media #{$down1200} { height: 500px; line-height: 500px; }
	@media #{$down1024} { height: 450px; line-height: 450px; }
	@media #{$down768}  { height: 400px; line-height: 400px; }
	@media #{$down600}  { height: auto;  line-height: normal; padding-top: 60px; padding-bottom: 60px; }

	h1 { 
		color: #ba302a; 
		@media #{$down600} 	{ font-size: 60px; }
		@media #{$down500} 	{ display: none; }
	}
	
	h2 {
		margin-top: -65px;
		margin-bottom: 40px;
		color: #fff;
		@media #{$down1600} { margin-top: -55px; }
		@media #{$down1400} { margin-top: -45px; margin-bottom: 30px; }
		@media #{$down1200} { margin-top: -38px; }
		@media #{$down768} 	{ margin-top: -30px; }
		@media #{$down600} 	{ margin-top: -26px; }
		@media #{$down500} 	{ margin-top: 0; }
	}
	
	.whiteBtn:hover { color: #fff; background: #ba302a; }
}
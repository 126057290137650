///////////////////////
// LES CHOSEN SELECT //
///////////////////////

.chosen-container .chosen-results li.highlighted { color: $color3; }
.chosen-container-single .chosen-search { display: none; }
.chosen-disabled .chosen-single { cursor: default; }
.chosen-disabled { opacity: 0.35 !important; cursor: default; }

.chosen-container {
    position: relative;
    display: inline-block;
    width: 100% !important;
    line-height: 48px;
    font-size: 16px;
    vertical-align: middle;
    background: transparent;
    user-select: none;
    z-index: 50;
    border-bottom: 2px solid #686868;

	.chosen-single {
		display: block;
		position: relative;
		width: 100%;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		z-index: 49;
		transition: background 300ms, color 300ms, border 300ms;

		span {
			display: block;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		div {
			display: block;
			position: absolute;
			width: 40px;
			height: 100%;
			top: 0;
            right: 8px;
            transform: rotate(90deg);
			transition: transform 500ms;

			b { display: none; }
		}
	}

	.chosen-drop {
		position: absolute;
		width: 100%;
		top: 0;
		border: none;
		background: $color1;
		opacity: 0;
		visibility: hidden;
		z-index: 48;
		transition: padding-top 500ms, opacity 500ms, box-shadow 500ms, visibility 0ms ease 500ms;
	}

	&.chosen-with-drop .chosen-drop {
		top: 0;
        padding-top: 55px;
		visibility: visible;
		opacity: 1;
		transition: padding 500ms, opacity 500ms, box-shadow 500ms;
	}

	.chosen-results {
		position: relative;
		max-height: 250px;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		li {
			display: none;
			padding: 10px 20px;
			margin: 0;
			font: 16px/20px $font1reg;
			list-style: none;
			word-wrap: break-word;
			-webkit-touch-callout: none;
            transition: color 300ms;

			&:first-child { padding-top:    25px; }
            &:last-child  { padding-bottom: 25px; }
            
			&.active-result {
				display: list-item;
				cursor: pointer;
				color: $color2;
            }
            
			&.disabled-result {
				display: list-item;
				color: rgba($color2, 0.4);
				cursor: default;
            }
            
			&.result-selected {
				font-family: $font1reg;
				cursor: default;
			}
		}
    }
    
    &.chosen-container-active {
        z-index: 50;
    
        &.chosen-with-drop .chosen-single div { transform: rotate(-90deg); }
    }
}

.chosen-container {
    background: transparent;

    .chosen-single, .chosen-default {
        font: 16px/56px $font1reg;
        color: #686868;
        background: transparent;

        div {
            background: url('../images/icones/pointer-red.svg') no-repeat center center;
            background-size: 10px auto;
            transition: transform 300ms;
        }

    }

    .chosen-results {
        li {
            &.active-result {
                color: #686868;

                &:hover { color: $color4; }
            }
            
            &.result-selected {
                color: $color2;

                &:hover { color: $color2; }
            }
        }
    }

    &.chosen-with-drop {
        .chosen-single { border-bottom: 2px solid rgba($color4, 0.2); }
    }
}

select {
    display: block;
    width: 100%;
    opacity: 0;

    &::-ms-expand { display: none; }

    &:focus, &:active {
        outline: none !important;
        border-color:  $color2;
    }
}

.select-field{
    position: relative;
    margin-bottom: 40px;
    select{
        display: block;
        position: relative;
        height: 56px;
        padding: 0 45px 0 20px;
        font: 16px/56px $font1reg;
        color: #686868;
        opacity: 1;
        background: $color3;
        text-overflow: ellipsis;
        white-space: nowrap;
        appearance: none;
        overflow: hidden;
        outline: none;
        border: none;
    }

    &:after {
        content: '';
        position: absolute;
        width: 40px;
        height: 56px;
        right: 8px;
        top: 0;
        background: url('../images/icones/pointer-red.svg') no-repeat center;
        background-size: 10px auto;
        z-index: 49;
        cursor: pointer;
        pointer-events: none;
        transform: rotate(90deg);
    }
}




    

#breadcrumbs {
	padding: 80px 0 30px 0;
	margin: 0;
	font: 12px/1 $font1bold;
	text-transform: uppercase;
	@media #{$down1400} { padding: 50px 0 30px 0; }
	@media #{$down768}  { padding: 40px 0 20px 0; }

	span {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		transition: color 300ms;
		&:hover { color: $color2; }
	}
}

.backOnTitle {
	margin-top: -55px;
	@media #{$down1400} { margin-top: -40px; }
	@media #{$down768}	{ margin-top: -20px; }
	@media #{$down500}	{ margin-top: -17px; }
}

#pageTitle {
	h1 {
		margin-left: -12px;
		color: #e5e4e4;
		@media #{$down1400} { margin-left: -10px; }
		@media #{$down1200} { margin-left: -8px; }
		@media #{$down768} 	{ margin-left: -6px; }
		@media #{$down600} 	{ margin-left: -5px; }
		@media #{$down500} 	{ margin-left: -3px; }
	}

	&.texture {

		h1 { color: rgba(#fff, 0.10); }

		#breadcrumbs {
			color: #fff;

			a {
				color: #fff;
				&:hover { color: $color2; }
			}
		}
	}

	a {
		text-transform: uppercase;

		&.white{
			color: #fff;
		}
	}

    #form-button {
        text-transform: none;
    }

	.shareSvg{
		position: absolute;
		right: 35px;
		top: 270px;

		@media #{$down1400} { top: 230px; }
		@media #{$down1200} { top: 220px; }
		@media #{$down1024} { top: 200px; }
		@media #{$down768} 	{ top: 175px; }

		&:hover {
			color: $color2;
			svg use { fill: $color2; }
			&.white svg use { fill: $color2; }
		}

		svg {
			width: 15px;
			height: 15px;
			margin-left: 10px;
			vertical-align: middle;

			use {
				fill: #000;
				transition: fill 300ms;
			}
		}

		&.white svg use { fill: #fff; }
	}
}

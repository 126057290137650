// FORMULAIRE __________
// Form
form {
	display: block;
	width: 100%;
}

// Error
div.error {
	position: absolute;
	padding-top: 8px;
	top: 100%;
	font: 12px/1 $font1reg;
	color: $color2;
}

.input-field, .text-field, .select-field, .checkbox-field, .radiobox-field, .file-field {
	position: relative;
	width: 100%;
	margin-bottom: 60px;

	@media #{$down1024} { margin-bottom: 55px; }
	@media #{$down600} { margin-bottom: 45px; }
}

input:-webkit-autofill, input:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    -webkit-text-fill-color: $color4 !important;
}

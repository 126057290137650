#coords {
	h2 a	{ color: $color2; cursor: default; }
	h3		{ font-family: $font1med; }
}

#formRow {
	position: relative;
	min-height: 500px;
	@media #{$down600} 	{ margin-left: -20px; width: calc(100% + 20px); }
	@media #{$down500} 	{ margin-left: -20px; width: calc(100% + 20px); }

	h6, h2, .row{
		@media #{$down600} 	{ padding-left: 10px; }
	}

	&::after {
		position: absolute;
		left: 100%;
		top: 0;
		bottom: 0;
		width: 100%;
		content: '';
		background: url('../../assets/images/content/background.jpg') repeat center center;
		@media #{$down600} 	{ width: 20px; }
	}

	#contactForm, #distributeurForm {
		@media #{$down1024} { padding: 0 20px; }
		@media #{$down600} 	{ padding: 0; }

		h2 { color: #fff; }
		.btn:hover { background: #fff; color: $color2;  }

		.btn{ float: right;}
	}
}
#innovation {

	#itemDesc {
		max-width: 600px;

		h2 {
			margin-bottom: 50px;
			@media #{$down1200} { margin-bottom: 40px; }
			@media #{$down768} 	{ margin-bottom: 30px; }
		}
		.intro { margin: 0; }

		li{
			margin-bottom: 20px;

			span{
				color: $color2;
				font: 17px/1.4 $font1bold;
			}
		}

		p{
			&:last-child{
				@media #{$down600} { padding-bottom: 25px; }
			}
		}
	}
	
	#prefooter {
		margin-top: 120px;
		@media #{$down1600}	{ margin-top: 100px; }
		@media #{$down1400}	{ margin-top: 80px; }
		@media #{$down1024}	{ margin-top: 70px; }
		@media #{$down768} 	{ margin-top: 60px; }
		@media #{$down600} 	{ margin-top: 0px; }
	}
}
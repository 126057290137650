#politiqueDeGarantie {
    .intro-container {
        h2 {
            margin-bottom: 30px;
            @media #{$down1200} { margin-bottom: 20px; }
            @media #{$down768} 	{ margin-bottom: 10px; }
        }

        .intro {
            margin: 0;
        }
    }
}

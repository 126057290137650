// CHEMIN VERS LES POLICES DE CARACTÈRES __________
$font-path: "../fonts/acumin/";

@font-face {
  font-family: 'AcuminPro-Regular';
  src: url('#{$font-path}AcuminPro-Regular.svg#AcuminPro-Regular') format('svg'),
       url('#{$font-path}AcuminPro-Regular.woff') format('woff'),
       url('#{$font-path}AcuminPro-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AcuminPro-Medium';
  src: url('#{$font-path}AcuminPro-Medium.svg#AcuminPro-Medium') format('svg'),
       url('#{$font-path}AcuminPro-Medium.woff') format('woff'),
       url('#{$font-path}AcuminPro-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AcuminPro-Bold';
  src: url('#{$font-path}AcuminPro-Bold.svg#AcuminPro-Bold') format('svg'),
       url('#{$font-path}AcuminPro-Bold.woff') format('woff'),
       url('#{$font-path}AcuminPro-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
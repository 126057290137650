// VISUEL DES TRANSITIONS DE PAGE ET DU CHARGEMENT
// La barre de chargement
#loaderbar {
    position: fixed;
    width: 0%;
    height: 5px;
    top: 0;
    z-index: 99999;
    background-color: $color2;
    transition: width 100ms;
}


// La transition entre les pages
#page-transition, #landing {
	position: fixed;
	height: 100%;
	top: -100%;
	right: 0;
	left: 0;
	background: url('../../assets/images/content/background.jpg') repeat center center;
	visibility: hidden;
	z-index: 9898;
}

#landing {
	top: 100vh;
	transition: top 1200ms cubic-bezier(0.77, 0, 0.175, 1), visibility 0ms ease 1200ms;

	.icon {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 250px;
		height: auto;
		fill: $color2;
		opacity: 0;
		transform: translate(-50%, -50%); -webkit-transform: translate(-50%, -50%);
		transition: opacity 800ms;
	}
}



.chargement {
	overflow-y: scroll;
	
    #landing {
		visibility: visible;
		top: 0;
		
		.icon {
			opacity: 1;
			transition: opacity 300ms ease 500ms;
		}
    }
}

.transition {
	#page-transition {
        top: 0;
		visibility: visible;
		transition: top 1000ms cubic-bezier(0.77, 0, 0.175, 1);
	}
	
	&.closeTransition {
		#page-transition {
			top: 100%;
			visibility: hidden;
			transition: top 1000ms cubic-bezier(0.77, 0, 0.175, 1), visibility 0ms ease 1000ms;
		}
	}
}


#publication{
    #newsInfo{
        padding-top: 125px;
        max-width: 500px;

        @media #{$down1600} { padding-top: 100px; }
        @media #{$down1400} { padding-top: 70px; }
        @media #{$down1200} { padding-top: 50px; }
        @media #{$down768} 	{ padding-top: 25px; }
        @media #{$down600} 	{ padding-top: 0; }

        h2{
            padding-top: 20px;
        }

        a{
            color: $color1;
            text-transform: uppercase;
            font: 12px/1.1 "AcuminPro-Medium";

            &:hover{
                color: $color2;
                transition: color 300ms;

                svg use{
                    fill: $color2;
                    transition: fill 300ms;
                }
            }
        }
    }

    #pubDesc{
        float: left;
    }

    .shareSvg{

        svg {
            width: 15px;
            height: 15px;
            margin-left: 10px;
            vertical-align: middle;

            use {
                fill: $color1;
                transition: fill 300ms;
            }
        }
    }

    .whiteSvg{
        svg use { fill: #fff; }
    }

    #shareLink{
        position: absolute;
        padding: 120px 0 350px 0;

        @media #{$down1400} { margin-top:-65px; padding: 0 0 30px 0; }

        .share{
            width: 250px;
            padding-bottom: 25px;
            border-bottom: solid 1px #d4d4d4;
        }

        p{
            margin-top: 25px;
        }
    }

    .centerTitle{
        text-align: center;
    }

    #productDesc {
        .sidenav{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 25%;
            margin-right: -100px;

            @media #{$down1400} { position: relative; }

            #shareLink {
                position: sticky;
                top: 50px;

                @media #{$down1400} {
                    position: relative;
                    display: inline-block;
                    float: left;
                    right: 0;
                    padding-top: 20px; 
                }
            }
        }
    }
}
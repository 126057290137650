.tiroirs {
	margin: 0;
	max-width: none;
	list-style-type: none;

	& > li {
		& > h2 {
			position: relative;
			margin: 0;
			cursor: pointer;
		}

		& > .tiroir {
			display: none;
			max-width: 100%;
			background: $color4;
			opacity: 0;
			transform: translateY(10px);
			transition: opacity 300ms, transform 300ms;
		}

		&.open {
			& > .tiroir {
				opacity: 1;
				transform: translateY(0);
				transition: transform 500ms ease 100ms, opacity 500ms ease 100ms;
			}
		}
	}
}

.tiroirs {
	& > li {
		background: #e2e2e2;
		margin-bottom: 15px;

		& > h2 {
			position: relative;
			padding: 50px 120px 50px 6%;
			font: 22px/1 $font1bold;
			color: $color1;
			text-transform: none;
			transition: color 400ms, background 400ms;

			@media #{$down1200} { font-size: 20px; padding: 40px 100px 40px 6%; }
			@media #{$down1024} { font-size: 18px; padding: 30px 80px 30px 6%;}
			@media #{$down768}  { font-size: 16px; padding-right: 60px; }

			.circle{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 60px;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				background: #fff;
				transition: background 300ms;

				@media #{$down1200} { right: 50px; }
				@media #{$down1024} { right: 40px; }
				@media #{$down768}  { right: 30px; }
				@media #{$down600}  { right: 15px; }

				&:before, &:after{
					position: absolute;
					top: 50%;
					left: 50%;
					content: '';
					transform: translate(-50%, -50%) rotate(90deg);
					width: 8px;
					height: 14px;
					transition: transform 300ms, opacity 300ms;

				}
				&:before{
					background: url('../images/icones/pointer.svg') no-repeat center / contain;
				}
				&:after{
					opacity: 1;
					z-index: 1;
					background: url('../images/icones/pointer-red.svg') no-repeat center / contain;
				}
			}
		}

		& > .tiroir {
			margin-bottom: 15px;
			& > div {
				padding: 0px 120px 35px 6%;

				@media #{$down1200} { padding-right: 100px; }
				@media #{$down1024} { padding-right: 80px; }
				@media #{$down768}  { padding-right: 60px; }

				&:first-child {
					padding: 35px 120px 35px 6%;

					@media #{$down1200} { padding-right: 100px; }
					@media #{$down1024} { padding-right: 80px; }
					@media #{$down768}  { padding-right: 60px; }
					@media #{$down600}  { padding-right: 6%; }
				}
			}
		}

		&:hover {
			.circle{
				background: $color2;
			}
			.circle:after{
				opacity: 0;
			}
		}

		&.open {
			.circle{
				background: $color2;
			}
			.circle:before{
				transform: translate(-50%, -50%) rotate(-90deg);
			}
			.circle:after{
				opacity: 0;
				transform: translate(-50%, -50%) rotate(-90deg);
			}
		}
	}
}

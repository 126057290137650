// UN TEXTAREA __________
.text-field {
	textarea {
		width: 100%;
		height: 45px;
		min-height: 0;
		padding: 15px 0 0 0;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid #686868;
		font: 16px/1.1 $font1reg;
		color: #fff;
		resize: none;
		overflow: hidden;
		transition: all 500ms;

		&:focus,
		&.valid { min-height: 90px; }
	}

	label {
		position: absolute;
		top: 0px;
        left: 0px;
		font: 16px/45px $font1reg;
		color: #686868;
		cursor: text;
		transition: 300ms $ease;
	}

	textarea:focus + label { color: $color2; }
	textarea:focus + label,
	textarea.valid + label {
		min-height: 90px;
		top: -12px;
		font-size: 11px;
		line-height: 12px;
		cursor: default;
		pointer-events: none;
	}
}

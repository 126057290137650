#dimension{
    background: $color2;
	
	.hamburger { background: #000;}
	
    h3{
        margin-bottom: 35px;
        color: #fff;
    }

	header {
		> div a {
			color: #fff;
			&:hover { color: #ba302a; }
        }
        
        #logo .icon{
            fill: #fff;
        }
    }
    
    #mainNav ul li a {
		color: #fff;
		&:hover { color: #ba302a; }
    }

    #breadcrumbs{
        color: #fff;

        a { 
            color: #fff; 
            &:hover { color: #ba302a; }
        }
    }

    #pageCalcul{
        text-align: center;

        h1 { 
            color: #ba302a; 
            @media #{$down600} 	{ font-size: 60px; }
        }
        
        h2 {
            margin-top: -65px;
            margin-bottom: 60px;
            color: #fff;
            @media #{$down1600} { margin-top: -55px; }
            @media #{$down1400} { margin-top: -45px; margin-bottom: 30px; }
            @media #{$down1200} { margin-top: -38px; }
            @media #{$down768} 	{ margin-top: -30px; }
            @media #{$down600} 	{ margin-top: -26px; }
            @media #{$down500} 	{ margin-top: -20px; }
        }

        
        #typeToile, #commande{
            
            ul li { display: inline-block; }

            li {
                margin-bottom: 10px;
                padding: 15px 40px 10px 40px;
                font-size: 15px;
                line-height: 21px;
                color: #fff;
                border: solid 1px #d4d4d4;
                margin-right: 15px;
                border-radius: 35px;
                
                &:last-child &:not(a) { margin-right: 0; }

            }
            
            .price{
                background: #fff;
                color: $color2;
            }

            a:hover{
                li, .price{
                    background: #ba302a;
                    color: #fff;
                    border: solid 1px #ba302a;
                }
            }
        }

        #calcul{ 
            display: inline-block;

            ul li { display: inline-block; }

            .detailcalcul{
                background: #ba302a;

                li {
                    border: none;
                    margin: 30px 45px 15px 0;

                    @media #{$down768} 	{ margin: 30px 35px 15px 0; }
                    @media #{$down600} 	{ margin: 30px 25px 15px 0; }
                    @media #{$down500} 	{ margin: 30px 10px 15px 0; }

                    &:first-child { margin-bottom: 25px; width: 165px; }
                    &:last-child { margin-right: 0; width: 135px; }
                    
                    p{
                        color: #fff;
                        margin-bottom: 0px;

                        span { 
                            position: absolute;
                            transform: rotate(45deg);
                            font-size: 50px; 
                            margin-top: -45px;
                            margin-left: -20px; 
                        }
                    }

                    .bold{
                        font: 17px/1.6 $font1bold;
                    }
                }
            }
        }
    }
}


.mainNewBloc {
	display: block;
	margin-bottom: 50px;
	@media #{$down1400} { margin-bottom: 30px; }
	
	&:last-child { margin-bottom: 0; }
	
	&:hover {
		
		.mainNewPic div { transform: scale(1.05); -webkit-transform: scale(1.05); }
		
		.link { 
			color: $color2; 
			&::after { width: 30px; }
		}
	}
	
	> div:first-child {
        position: relative;
        height: 630px;
		overflow: hidden;
		@media #{$down1600} 	{ height: 580px; }
        @media #{$down1400} 	{ height: 550px; }
		@media #{$down1200} 	{ height: 500px; }
		@media #{$down1024}		{ height: 450px; }
		@media #{$down768}		{ height: 380px; }
		@media #{$down600}		{ height: 300px; }
        @media #{$down500}  	{ height: 250px; }
		
		div {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			transition: all 300ms;
		}
	}

    > div:last-child {
		min-height: 630px;
		line-height: 630px;
		@media #{$down1600}		{ min-height: 580px; line-height: 580px; }
		@media #{$down1400} 	{ min-height: 550px; line-height: 550px; }
		@media #{$down1200} 	{ min-height: 500px; line-height: 500px; }
		@media #{$down1024} 	{ min-height: 450px; line-height: 450px; }
		@media #{$down768} 		{ min-height: 380px; line-height: 380px; }
		@media #{$down600}  	{ min-height: 0; line-height: normal; padding: 0 20px; }
		@media #{$down500}  	{ padding: 0 20px; }

    	.centerize {
			position: relative;
			z-index: 1;
			@media #{$down600} 	{ padding-top: 30px; padding-right: 0; }
    		@media #{$up600}   	{ padding-left: 30px; padding-right: 12%; }
    		@media #{$up768}  	{ padding-right: 20px; padding-left: 15%; }
    		@media #{$up1200} 	{ padding-left: 18%; }

			h3 { max-width: 500px; }
		}
	}
}
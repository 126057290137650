// RESET STYLE __________
* { box-sizing: border-box; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; -webkit-backface-visibility: hidden; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img,
ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time,
mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }
blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }
html { overflow-x: hidden; }
body { line-height: 1; }
h1, h2, h3, h4, h5, h6 { font-weight: normal; }
ol, ul { list-style: none; }
strong, b { font-weight: bold; }
input:focus { outline: none; }
blockquote, q { quotes: none; }
table { border-collapse: collapse; border-spacing: 0; }
a[ng-click], div[ng-click], p[ng-click], span[ng-click], img[ng-click], a[ui-sref], div[ui-sref], p[ui-sref], span[ui-sref], img[ui-sref] { cursor: pointer; }
input::-ms-clear { display: none; }
input[type="search"] { -webkit-appearance: none; -webkit-box-shadow: none; -webkit-border-radius: 0; } 
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { -webkit-appearance:none; }
sup 	{ margin-left: 5px; font-size: 0.7em; bottom: 1ex; vertical-align: 0; position: relative; }


// Aucun style pour les input et les textarea sur le focus ou hover
textarea:hover, input:hover, textarea:active, input:active, textarea:focus, input:focus, button:focus, button:active, button:hover, label:focus, .btn:active, .btn.active{
    outline: 0px !important;
    -webkit-appearance: none;
}

// Aucun style sur l'autofill (variable à changer selon le background color)
.slick-slide, .slick-slide a, .slick-slide:focus { outline: none !important; }
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus input:-webkit-autofill, textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

a { text-decoration: none; color: inherit; -webkit-tap-highlight-color: transparent; }

@-webkit-keyframes autofill 		{ to { color: #fff; background: transparent; }}
input:-webkit-autofill 				{ -webkit-animation-name: autofill; -webkit-animation-fill-mode: both; }
#homeBanner {
	@media #{$down1024} { padding-left: 35px; }

	.videoWrapper {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: hidden;
		background: url('../../assets/images/banner/home.jpg') no-repeat center;
		background-size: cover;

		@media #{$down1024}  { right: 0; }

		&:before {
			content: '';
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			background: rgba(#000, 0.3);
			z-index: 1;
		}

		video {
			position: absolute;
			width: auto;
			height: auto;
			min-width: 100%;
			min-height: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			-o-object-fit: cover;
				object-fit: cover;
		}
	}

	.col24 {
		position: relative;
		height: calc(100vh - 80px);
		min-height: 600px;
		background: url('../../assets/images/banner/home.jpg') no-repeat center center;
		background-size: cover;
		@media #{$down1024} { height: calc(100vh - 70px); min-height: 450px; }
		@media #{$down768} 	{ min-height: 350px; }
		@media #{$down600} 	{ min-height: 330px; }
		@media #{$down500} 	{ min-height: 280px; }

		> h1 {
			display: none;
			position: absolute;
			left: -120px;
			bottom: -44px;
			color: $color1;
			z-index: 1;
			@media #{$down1600} { left: -100px; }
			@media #{$down1400} { bottom: -35px; left: -80px; }
			@media #{$down1200} { bottom: -30px; left: -60px; }
			@media #{$down1024} { bottom: 10px; left: auto; right: 0; }
			@media #{$down768}  { bottom: 0; right: 50px; }
			@media #{$down600}  { bottom: 35px; right: 0; }
			@media #{$down500}  { bottom: 40px; right: auto; left: 20px; }
		}

		.centerize {
			position: absolute;
			left: 0;
			z-index: 1;
			top: 50%;
			margin: -50px 0 0 -80px;
			transform: translateY(-50%); -webkit-transform: translateY(-50%);
			@media #{$down1600} { margin-left: -65px; }
			@media #{$down1400} { margin-left: -50px; }
			@media #{$down1200} { margin-left: -35px; }
			@media #{$down1024} { margin-left: -25px; }
			@media #{$down768}  { margin-left: -25px; margin-top: -40px; }
			@media #{$down600}  { margin-left: -25px; margin-top: -80px; }

			div {
				margin-bottom: 15px;

				@media #{$down768} 	{ margin-bottom: 10px; }
				@media #{$down600} 	{ margin-bottom: 7px; }
				@media #{$down500} 	{ margin-bottom: 5px; }

				&:nth-child(1) h1 { transition-delay: 800ms; }
				&:nth-child(2) h1 { transition-delay: 900ms; }
				&:nth-child(3) h1 { transition-delay: 1000ms; }

				h1 {
					display: inline-block;
					position: relative;
					max-width: 0px;
					line-height: 80px;
					color: #fff;
					overflow: hidden;
					background: $color2;
					transition: max-width 800ms cubic-bezier(0.895, 0.03, 0.685, 0.22);

					@media #{$down1400} { line-height: 70px; }
					@media #{$down1200} { line-height: 64px; }
					@media #{$down768}  { line-height: 50px; }
					@media #{$down600}  { margin-bottom: 10px; }
					@media #{$down500}  { line-height: 44px; }

					&:last-child { margin: 0; }

					&[data-scrollfire="fire"] {
						max-width: 470px;
					}

					span {
						display: inline-block;
						padding: 25px 40px 5px 40px;
						font-size: 80px;

						@media #{$down1400} { padding: 20px 40px 0 40px; font-size: 70px; }
						@media #{$down1200} { padding: 15px 40px 0 40px; font-size: 64px; }
						@media #{$down768} 	{ padding: 15px 30px 5px 30px; font-size: 50px; }
						@media #{$down600} 	{ font-size: 48px; }
						@media #{$down500} 	{ font-size: 46px; }
					}
				}
			}
		}
	}
}

// Bloc remorques
#homeRemorques {
	position: relative;
	z-index: 1;

	&::before {
		position: absolute;
		left: 0;
		right: 100px;
		bottom: -100px;
		top: 40%;
		content: '';
		background: $color4;
		@media #{$down1600} { right: 85px; }
		@media #{$down1400} { right: 65px; }
		@media #{$down1200} { right: 50px; }
		@media #{$down1024} { right: 20px; }
		@media #{$down768} 	{ right: 0; }
		@media #{$down600} 	{ top: 20%; }
	}

	#homeRemorquesWrapper {
		padding-left: 50px;
		@media #{$down1400} { padding-left: 30px; }
		@media #{$down1024} { padding-left: 0; margin-left: -10px; width: calc(100% + 20px); }

		> div {
			margin-top: -140px;
			padding-right: 50px;
			padding-left: 50px;
			@media #{$down1400} { padding-right: 30px; padding-left: 30px; }
			@media #{$down1024} { margin-top: -100px; padding-right: 20px; padding-left: 20px; }
			@media #{$down768} 	{ margin-top: -80px; padding-right: 10px; padding-left: 10px; }
			@media #{$down600} 	{
				margin-top: -60px;
				margin-bottom: 20px;
				&:last-child { margin-bottom: 0; }
				&:first-child { margin-top: -160px; }
			}
			@media #{$down500} { margin-bottom: 10px; }

			a {
				position: relative;
				display: block;
				width: 100%;
				line-height: 0;
				overflow: hidden;

				img {
					display: block;
					width: 100%;
					transition: transform 300ms;
				}

				div {
					position: absolute;
					left: 0;
					top: 0;
					padding: 50px 20px 0 50px;
					@media #{$down1200} { padding: 40px 20px 0 30px; }
					@media #{$down768} 	{ padding: 40px 20px 0 20px; }

					h3 	{
						margin-bottom: 20px;
						color: #fff;
						@media #{$down1024} { margin-bottom: 10px;  }
					}

					.link {
						margin: 0;
						color: #fff;
						&::after { background: #fff; }
					}
				}

				&:hover img { transform: scale(1.05); -webkit-transform: scale(1.05); }
			}
		}
	}
}

// Bloc About
#about {
	position: relative;
	height: 750px;
	line-height: 750px;
	text-align: center;
	@media #{$down1500} { height: 575px; line-height: 575px; }
	@media #{$down1200} { height: 550px; line-height: 550px; }
	@media #{$down1024} { height: 450px; line-height: 450px; }
	@media #{$down768} 	{ height: 400px; line-height: 400px; }
	@media #{$down600} 	{ height: 350px; line-height: 350px; }
	@media #{$down500} 	{ height: 300px; line-height: 300px; }
	z-index: 1;
	background: url('../../assets/images/content/about.jpg') no-repeat center right;
	background-size: cover;

	h2 { color: #fff; max-width: 750px; margin-right: auto; margin-left: auto; }

	.centerize {
		position: relative;
		z-index: 1;
	}

	.col24 {
		overflow: hidden;
	}
}

// Bloc Products
#homeProducts { position: relative; z-index: 2;}
#homeProducts > .col24 {
	position: relative;
	margin-top: -100px;
	z-index: 1;
	@media #{$down1600} { margin-top: -80px; }
	@media #{$down1400} { margin-top: -60px; }
	@media #{$down768} 	{ margin-top: -40px; }
	@media #{$down600} 	{ margin-top: 0; }

	.wrap.relative { overflow: visible; }

	.floatRight {
		padding-top: 320px;
		z-index: 1;
		@media #{$down1600} { padding-top: 270px; }
		@media #{$down1400} { padding-top: 240px; }
		@media #{$down1200} { padding-top: 180px; }
		@media #{$down768} 	{ padding-top: 160px; }
		@media #{$down600} 	{ padding-top: 50px; margin-bottom: 10px; }

		h1 {
			position: absolute;
			left: -17%;
			top: 200px;
			color: rgba(#2a2a2a, 0.1);
			z-index: -1;
			@media #{$down1600} { top: 150px; }
			@media #{$down1200} { left: -70px; top: 120px; }
			@media #{$down1024} { left: -50px; top: 120px; }
			@media #{$down768} 	{ left: -30px; }
			@media #{$down600} 	{
				text-align: center;
				width: 100%;
				top: 10px;
				left: 0;
			}
		}
	}

	.desc {
		position: absolute;
		left: 0;
		bottom: 0;
		padding: 0 20px 50px 50px;
		@media #{$down1200} { padding: 0 20px 30px 30px; }
		@media #{$down768} 	{ padding: 0 20px 20px 20px; }

		h4 { margin-bottom: 10px; }
		h6 {
			margin-bottom: 25px;
			@media #{$down1024} { color: #fff; }
			@media #{$down768} { margin-bottom: 15px; }

			br{
				display: none;
				@media #{$down1024} { display: block; }
			}
		}
	}

	#item1 {

		.relative {
			margin-bottom: 80px;
			@media #{$down1200} { margin-bottom: 50px; }
		}

		> div:last-child {

			text-align: center;
			@media #{$down768} { text-align: left; }
			@media #{$down600} { text-align: center; }

			div {
				display: inline-block;
				text-align: left;
				@media #{$down600} { text-align: center; }
			}

			h2 {
				font-size: 30px;
				margin-bottom: 30px;
				@media #{$down768} { font-size: 25px; }

				span { font-family: $font1reg; }
			}
		}
	}

	.relative {
		display: block;
		overflow: hidden;

		img {
			width: 100%;
			transition: transform 300ms; -webkit-transition: -webkit-transform 300ms;
		}

		&:hover {

			img { transform: scale(1.05); -webkit-transform: scale(1.05); }

			.link {
				color: $color2;

				&::after {
					width: 30px;
				}
			}
		}
	}
}

// Bloc New
#homeNew {
	.mainNewBloc 	{ margin-bottom: 0; }
	.col24.relative {

		h2 {
			@media #{$down600} { margin-bottom: 5px; }
		}

		.link {
			position: absolute;
			right: 0;
			bottom: 10px;
			@media #{$down600} {
				position: static;
				right: auto;
				top: auto;
                margin-bottom: 30px;

                &:before, &:after {
                    content: none;
                }
            }
        }
    }
}

#distributeur{
    background: url('../../assets/images/content/background.jpg') repeat center center;
	
	.hamburger { background: #000;}
	
	header {
		> div a {
			color: #fff;
			&:hover { color: $color2; }
		}
    }
    
    #mainNav ul li a {
		color: #fff;
		&:hover { color: $color2; }
    }

    .title{
        h2{
            margin-top: -65px;
            color: #fff;
            margin-bottom: 150px;

            @media #{$down1600} { margin-top: -55px; }
            @media #{$down1400} { margin-top: -45px;  margin-bottom: 100px; }
            @media #{$down1200} { margin-top: -38px; }
            @media #{$down768} 	{ margin-top: -30px; }
            @media #{$down600} 	{ margin-top: -26px; }
            @media #{$down500} 	{ margin-top: -20px; }
        }

    }

    .titleRemorque { margin-top: 35px; }

    #stores{

        .tiroir{
            padding: 50px 100px 50px 6%;
            span{
                font: 17px/1.6 $font1bold;
            }

            p{
                margin-bottom: 0px;
            }
            .courriel{
                text-decoration: underline;
                p{
                    margin-top: 15px;
                    margin-bottom: 25px;
                }
            }
            .coords{
                margin-bottom: 15px;
            }

            .stores{
                padding: 0;
                ul li { display: inline-block; }

                li{
                    &:first-child{
                        width: 300px;
                        @media #{$down1400} { width: 275px; }
                        @media #{$down1200} { width: 250px; }
                        @media #{$down768} 	{ width: 200px;}
                        @media #{$down600} 	{ width: 150px; }
                    }
                }

                p{ padding-bottom: 5px; }

                .btn{
                    position: absolute;
                    bottom: 55px;
                    right: 30%;
                    @media #{$down1600} { right: 20%; }
                    @media #{$down1200} { right: 10%; }
                    @media #{$down768} 	{ position: relative; right: 0; bottom: 0; margin-top: 10px; }
                }
            }
        }
    }
}


//------------\\
//--- Helper ---\\
//----------------\\

////////////////////////////////////////////////////////////////////////////////////
// DISPOSITION
////////////////////////////////////////////////////////////////////////////////////
// Display ----------
.block        { display: block;        }
.inline-block { display: inline-block; }
.table        { display: table;        }
.tableCell    { display: table-cell;   }
.flex         { display: -webkit-box; display: -ms-flexbox; display: flex; position: absolute; width: 100%; top: 0; bottom: 0; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; justify-content: center; }
.centerize	  { display: inline-block; line-height: normal; vertical-align: middle; }

// Position ----------
.absolute 	{ position: absolute; }
.relative 	{ position: relative; }
.fixed    	{ position: fixed;    }
.sticky   	{ position: sticky; top: 50px; }

// Float ----------
.floatLeft  { float: left;  }
.floatRight { float: right; }



////////////////////////////////////////////////////////////////////////////////////
// TEXTE
////////////////////////////////////////////////////////////////////////////////////
// Text-align ----------
.textLeft   { text-align: left;   }
.textCenter { text-align: center; }
.textRight  { text-align: right;  }

// Vertical-align ----------
.alignTop    { vertical-align: top; }
.alignMiddle { vertical-align: middle; }
.alignBottom { vertical-align: bottom; }

// Text-transform ----------
.caps   { text-transform: capitalize; }
.allCaps   { text-transform: uppercase; }



////////////////////////////////////////////////////////////////////////////////////
// LES WRAPPERS
////////////////////////////////////////////////////////////////////////////////////
.wrapper1600 { max-width: 1600px; margin: 0 auto; @media #{$down1600} { margin: 0; }}
.wrapper1500 { max-width: 1500px; margin: 0 auto; @media #{$down1500} { margin: 0; }}
.wrapper1400 { max-width: 1400px; margin: 0 auto; @media #{$down1400} { margin: 0; }}
.wrapper1300 { max-width: 1300px; margin: 0 auto; @media #{$down1300} { margin: 0; }}
.wrapper1200 { max-width: 1200px; margin: 0 auto; @media #{$down1200} { margin: 0; }}
.wrapper1100 { max-width: 1100px; margin: 0 auto; @media #{$down1100} { margin: 0; }}
.wrapper1024 { max-width: 1024px; margin: 0 auto; @media #{$down1024} { margin: 0; }}

// Padding XY
.xPadding 			  { padding-right: 160px; padding-left: 160px;
  @media #{$down1600} { padding-right: 130px; padding-left: 130px; }
  @media #{$down1400} { padding-right: 100px; padding-left: 100px; }
  @media #{$down1200} { padding-right: 70px; padding-left: 70px; }
  @media #{$down1024} { padding-right: 30px; padding-left: 30px; }
  @media #{$down768}  { padding-right: 20px; padding-left: 20px; }
  @media #{$down500}  { padding-right: 20px; padding-left: 20px; }
}
.xRightPadding 		  { padding-right: 160px;
  @media #{$down1600} { padding-right: 130px; }
  @media #{$down1400} { padding-right: 100px; }
  @media #{$down1200} { padding-right: 70px; }
  @media #{$down1024} { padding-right: 30px; }
  @media #{$down768}  { padding-right: 20px; }
  @media #{$down500}  { padding-right: 10px; }
}
.xLeftPadding 		  { padding-left: 160px;
  @media #{$down1600} { padding-left: 130px; }
  @media #{$down1400} { padding-left: 100px; }
  @media #{$down1200} { padding-left: 70px; }
  @media #{$down1024} { padding-left: 30px; }
  @media #{$down768}  { padding-left: 20px; }
  @media #{$down500}  { padding-left: 10px; }
}

// xsPadding
.xsPadding  			{ padding-right: 60px; padding-left: 60px;
  @media #{$down1600}	{ padding-right: 40px; padding-left: 40px; }
  @media #{$down1200}	{ padding-right: 30px; padding-left: 30px; }
  @media #{$down1024}	{ padding-right: 20px; padding-left: 20px; }
  @media #{$down600}	{ padding-right: 0; padding-left: 0; }
}

.xsRightPadding  		{ padding-right: 60px;
  @media #{$down1600}	{ padding-right: 40px; }
  @media #{$down1200}	{ padding-right: 30px; }
  @media #{$down1024}	{ padding-right: 20px; }
  @media #{$down600}	{ padding-right: 0; }
}
.xsLeftPadding  		{ padding-left: 60px;
  @media #{$down1600}	{ padding-left: 40px; }
  @media #{$down1200}	{ padding-left: 30px; }
  @media #{$down1024}	{ padding-left: 20px; }
  @media #{$down600}	{ padding-left: 0; }
}

// yPadding
.ylPadding			  	{ padding-top: 160px; padding-bottom: 160px;
  @media #{$down1600} 	{ padding-top: 120px; padding-bottom: 120px; }
  @media #{$down1400} 	{ padding-top: 100px; padding-bottom: 100px; }
  @media #{$down1024} 	{ padding-top: 80px; padding-bottom: 80px; }
  @media #{$down768}  	{ padding-top: 60px; padding-bottom: 60px; }
}

.ylTopPadding			{ padding-top: 160px;
  @media #{$down1600}	{ padding-top: 120px; }
  @media #{$down1400}	{ padding-top: 100px; }
  @media #{$down1024}	{ padding-top: 80px; }
  @media #{$down768} 	{ padding-top: 60px; }
}

.ylBottomPadding 		{ padding-bottom: 160px;
  @media #{$down1600}	{ padding-bottom: 120px; }
  @media #{$down1400}	{ padding-bottom: 100px; }
  @media #{$down1024}	{ padding-bottom: 80px; }
  @media #{$down768} 	{ padding-bottom: 60px; }
}

.yPadding			  	{ padding-top: 120px; padding-bottom: 120px;
  @media #{$down1600} 	{ padding-top: 100px; padding-bottom: 100px; }
  @media #{$down1400} 	{ padding-top: 80px; padding-bottom: 80px; }
  @media #{$down1024} 	{ padding-top: 70px; padding-bottom: 70px; }
  @media #{$down768}  	{ padding-top: 60px; padding-bottom: 60px; }
}
.yTopPadding			{ padding-top: 120px;
  @media #{$down1600}	{ padding-top: 100px; }
  @media #{$down1400}	{ padding-top: 80px; }
  @media #{$down1024}	{ padding-top: 70px; }
  @media #{$down768} 	{ padding-top: 60px; }
}
.yBottomPadding 		{ padding-bottom: 120px;
  @media #{$down1600}	{ padding-bottom: 100px; }
  @media #{$down1400}	{ padding-bottom: 80px; }
  @media #{$down1024}	{ padding-bottom: 70px; }
  @media #{$down768} 	{ padding-bottom: 60px; }
}

// ysPadding
.ysPadding  			{ padding-top: 80px; padding-bottom: 80px;
  @media #{$down1200}	{ padding-top: 60px; padding-bottom: 60px; }
}

.ysTopPadding  			{ padding-top: 80px;
  @media #{$down1200}	{ padding-top: 60px; }
}
.ysBottomPadding  		{ padding-bottom: 80px;
  @media #{$down1200}	{ padding-bottom: 60px; }
}

// yesPadding
.yesTopPadding  			{ padding-top: 40px;
  @media #{$down1200}	{ padding-top: 20px; }
}

////////////////////////////////////////////////////////////////////////////////////
// LE 0 ABSOLUE
////////////////////////////////////////////////////////////////////////////////////
// Padding ----------
.noTopPadding	 { padding-top: 0;    }
.noRightPadding	 { padding-right: 0;  }
.noBottomPadding { padding-bottom: 0; }
.noLeftPadding   { padding-left: 0;   }
.noXPadding      { padding-right: 0; padding-left: 0; }
.noYPadding      { padding-top: 0; padding-bottom: 0; }
.noPadding       { padding: 0; }

// Margin ----------
.noTopMargin	{ margin-top: 0;    }
.noRightMargin  { margin-right: 0;  }
.noBottomMargin { margin-bottom: 0; }
.noLeftMargin   { margin-left: 0;   }
.noXMargin      { margin-right: 0; margin-left: 0; }
.noYMargin      { margin-top: 0; margin-bottom: 0; }

// Positionnement ----------
.top0    { top: 0;    }
.right0  { right: 0;  }
.bottom0 { bottom: 0; }
.left0   { left: 0;   }
.trbl0   { top: 0; right: 0; bottom: 0; left: 0; }



////////////////////////////////////////////////////////////////////////////////////
// MISCELLANEOUS
////////////////////////////////////////////////////////////////////////////////////
// White-space ----------
.nowrap { white-space: nowrap; }

// Clear les float ----------
.clearfloat 		{ display: block; clear: both; }
.clearfix::after	 { display: table; content: ''; clear: both; }

// Empêche le scroll
.noscroll {
    position: fixed;
    width: 100%;
    overflow-y: scroll;

    @media screen and (max-width: 768px) {
        overflow-y: hidden;
    }
}

// Empêche la sélection du texte
.noselect { user-select: none; }



////////////////////////////////////////////////////////////////////////////////////
// HIDE AND SEEK
////////////////////////////////////////////////////////////////////////////////////
// Hide ----------
.hide { display: none;  }
@media #{$down1800} { .hide_1800 { display: none; }}
@media #{$down1700} { .hide_1700 { display: none; }}
@media #{$down1600} { .hide_1600 { display: none; }}
@media #{$down1500} { .hide_1500 { display: none; }}
@media #{$down1400} { .hide_1400 { display: none; }}
@media #{$down1300} { .hide_1300 { display: none; }}
@media #{$down1200} { .hide_1200 { display: none; }}
@media #{$down1100} { .hide_1100 { display: none; }}
@media #{$down1024} { .hide_1024 { display: none; }}
@media #{$down1000} { .hide_1000 { display: none; }}
@media #{$down900}  { .hide_900  { display: none; }}
@media #{$down800}  { .hide_800  { display: none; }}
@media #{$down768}  { .hide_768  { display: none; }}
@media #{$down700}  { .hide_700  { display: none; }}
@media #{$down600}  { .hide_600  { display: none; }}
@media #{$down500}  { .hide_500  { display: none; }}
@media #{$down400}  { .hide_400  { display: none; }}
@media #{$down300}  { .hide_300  { display: none; }}

////////////////////////////////////////////////////////////////////////////////////
// I SEE YOU
////////////////////////////////////////////////////////////////////////////////////
// Show ----------
.show { display: block;  }
.show_1800 { display: none; @media #{$down1800} { display: block; }}
.show_1700 { display: none; @media #{$down1700} { display: block; }}
.show_1600 { display: none; @media #{$down1600} { display: block; }}
.show_1500 { display: none; @media #{$down1500} { display: block; }}
.show_1400 { display: none; @media #{$down1400} { display: block; }}
.show_1300 { display: none; @media #{$down1300} { display: block; }}
.show_1200 { display: none; @media #{$down1200} { display: block; }}
.show_1100 { display: none; @media #{$down1100} { display: block; }}
.show_1024 { display: none; @media #{$down1024} { display: block; }}
.show_1000 { display: none; @media #{$down1000} { display: block; }}
.show_900  { display: none; @media #{$down900}  { display: block; }}
.show_800  { display: none; @media #{$down800}  { display: block; }}
.show_768  { display: none; @media #{$down768}  { display: block; }}
.show_700  { display: none; @media #{$down700}  { display: block; }}
.show_600  { display: none; @media #{$down600}  { display: block; }}
.show_500  { display: none; @media #{$down500}  { display: block; }}
.show_400  { display: none; @media #{$down400}  { display: block; }}
.show_300  { display: none; @media #{$down300}  { display: block; }}


.dynamic {
	max-width: 900px;
	& > :first-child 		{ margin-top: 0px; }
	& > :last-child	 		{ margin-bottom: 0 !important; }
	& > p:last-child > * 	{ margin-bottom: 0 !important; }

	h1 {
		margin: 60px 0 30px 0;
		font: 30px/1.25 $font1bold;
		color: $color1;
		text-transform: none;
		@media #{$down1400} { margin-bottom: 25px; }
		@media #{$down1200} { margin: 50px 0 20px 0; font-size: 28px; }
		@media #{$down1024} { font-size: 24px; }
		@media #{$down600} 	{ margin: 45px 0 15px 0; }
	}

	h2 {
		margin: 60px 0 30px 0;
		font: 25px/1.25 $font1bold;
		color: $color1;
		text-transform: none;
		@media #{$down1400} { margin-bottom: 25px; }
		@media #{$down1200} { margin: 50px 0 20px 0; }
		@media #{$down1024} { font-size: 22px; }
		@media #{$down600} 	{ margin: 45px 0 15px 0; font-size: 20px; }
	}

	p {
		margin: 0 0 25px 0;
		font: 17px/1.9 $font1reg;
		color: #787878;
		@media #{$down768} 	{ margin-bottom: 20px; }
		@media #{$down600} 	{ font-size: 16px; }
	}

	ul,
	ol {
		margin: 0 0 20px 0;
		list-style-type: none;

		li {
			position: relative;
			margin: 0 0 12px 0;
			font: 17px/1.4 $font1reg;
			color: #787878;
			@media #{$down600} 	{ font-size: 16px; }
			&:last-child		{ margin-bottom: 0; }
		}

		+ p {
			margin-top: 25px;
		}
	}

	ul {
		margin: 0 0 20px 0;
		list-style-type: none;
		counter-reset: item;

		> li {
			padding: 0 0 0 30px;
		}

		> li::before {
			position: absolute;
			left: 2px;
			top: 8px;
			width: 15px;
			height: 1px;
			content: '';
			background: $color2;
			@media #{$down600} { top: 7px; }
		}
	}

	ol {
		margin: 0 0 12px 0;
		list-style: none;
		counter-reset: li;

		> li {
			padding: 0 0 0 26px;
		}

		> li::before {
			position: absolute;
			left: 2px;
			top: 0;
			content: counter(li) ".";
			counter-increment: li;
			display: inline-block;
			font-weight: 500;
			color: $color2;
		}
	}

	strong,
	b {
		font-weight: 700;
	}

	em,
	i {
		font-style: italic;
	}

	a {
		color: $color2;
		transition: color 300ms;

		&:hover {
			color: $color1;
		}
	}
}

.texture .dynamic {
	h1, h2 { color: #fff; }
}

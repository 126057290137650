#remorques {
	background: url('../../assets/images/content/background.jpg') repeat center center;
	
	.hamburger { background: #000;}
	
	header {
		> div a {
			color: #fff;
			&:hover { color: $color2; }
		}
	}

	#mainNav ul li a {
		color: #fff;
		&:hover { color: $color2; }
	}

	#itemDesc {
		max-width: 600px;

		h2 {
			margin-bottom: 50px;
			@media #{$down1200} { margin-bottom: 40px; }
			@media #{$down768} 	{ margin-bottom: 30px; }
		}
		.intro { margin: 0; color: #fff; }

		.link{
			font-size: 16px;
		}
	}

	#productDesc{
		p, li { color: #fff; }

		span{
			font: 17px/1.4 $font1bold;
		}
	}
}
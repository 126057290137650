header {
	position: absolute;
	left: 0;
	right: 0;
    height: 90px;
	padding: 0 0 0 80px;

	@media #{$down1600} { padding-left: 40px; }
	@media #{$down1200} { padding-left: 0; }
	@media #{$down1024} {
		position: relative;
		left: auto;
		right: auto;
		height: 70px;
	}

	#logo {
		float: left;
		line-height: 90px;
		width: 180px;
		max-width: 50%;
		@media #{$down1200} { margin-left: 20px; }
		@media #{$down1024} { line-height: 70px; }
		@media #{$down500} 	{ margin-left: 10px; }

		.icon {
			fill: $color2; width: 100%;
			height: 90px;
			vertical-align: middle;
			@media #{$down1024} { height: 70px; }
		}
	}

	> div {
		position: absolute;
		right: 0;
		top: 0;
        z-index: 300;
		font: 14px/80px $font1med;
		@media #{$down1024} { line-height: 70px; }

		a {
			float: left;
			padding: 0 30px;
            line-height: 90px;
			transition: color 300ms;
            @media #{$down1024} { line-height: 70px; }
			@media #{$down768} { padding: 0 20px; }

			&:hover { color: $color2; }
		}

		div { float: right; }
	}

    #header-button-container {
        position: relative;
        float: right;
        margin-right: 220px;
        height: 90px;
        display: flex;
        align-items: center;
        z-index: 100;

        @media #{$down1024} { height: 70px; }
        @media #{$down700} { display: none; }
    }
}

#mainNav {
	position: relative;
	margin: 0;
    margin-left: 260px;
    height: 90px;
	line-height: 0;
	z-index: 50;
	transition: z-index 0ms ease 400ms;

    @media #{$down1600} { margin-left: 220px; }
    @media #{$down1400} { margin-left: 230px; }
    @media #{$down1024} { height: 0px; }

	ul {
		display: inline-block;
        height: 90px;

		@media #{$down1300} { display: none; }

		li {
			float: left;
			font: 15px/60px $font1bold;
            line-height: 70px;

			@media #{$down1024} { line-height: 55px; }

			a {
				display: block;
				padding: 14px 20px 6px 20px;
				transition: color 300ms;
				@media #{$down1400} { padding: 13px 15px 7px 15px; }
				@media #{$down1200} { padding: 13px 12px 7px 12px; }

				&:hover { color: $color2; }
			}
		}
	}
}


#reasons {
	position: relative;
	text-align: center;
	z-index: 1;
	@media #{$down600} { padding-bottom: 0; }
	
	> img { 
		position: absolute; 
		left: 0;
		top: 0;
		width: 100%; 
		z-index: -1; 
	}
	
	h2 { margin: 0; color: #fff; }
}

#qualityCycle {
	margin: 0;
	height: 100%;

	.slide {
		height: 800px;
		line-height: 800px;	
		@media #{$down1600} { height: 750px; line-height: 750px; }
		@media #{$down1400} { height: 650px; line-height: 650px; }
		@media #{$down1024} { height: 550px; line-height: 550px; }
		@media #{$down768} 	{ height: 480px; line-height: 480px; }
		@media #{$down600} 	{ height: auto; line-height: normal; }
	}
	
	.slidePic { 
		position: relative;
		height: 100%;
		z-index: 1;
		@media #{$down600} 	{ height: 400px; line-height: 400px; }
		@media #{$down500} 	{ height: 350px; line-height: 350px; }
		
		.slidePicBox {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			padding: 0 50px;
			z-index: -1;
			@media #{$down1200} { padding: 0; }
			@media #{$down600}  { padding: 0 75px; }
			
			div {
				height: 100%;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: cover;
				
				&.bottom { background-position: center bottom; }
				&.top 	 { background-position: center top; }
			}
		}
		
		h1 {
			display: inline-block;
			vertical-align: middle;
			margin: 0;
			font-size: 90px; 
			color: #fff;	
			@media #{$down1600} { font-size: 80px; }			
			@media #{$down1200} { font-size: 70px; }
			@media #{$down1024} { font-size: 60px; }
			@media #{$down768} 	{ font-size: 40px; }
			@media #{$down600} 	{ padding: 0 40px; }
			@media #{$down500} 	{ padding: 0 30px; font-size: 30px; }
		}	
	}
	
	.slideDesc {
		@media #{$down1200} { text-align: left; }
	
		.centerize {
			max-width: 240px; 
			margin: 0 auto;
			text-align: left;
			padding-left: 50px;
			@media #{$down1200} { margin: 0; padding-left: 80px; max-width: 270px; }
			@media #{$down768} 	{ padding-left: 60px; }
			@media #{$down600} 	{ max-width: none; padding: 30px 70px 0 70px; }
			@media #{$down500} 	{ max-width: none; padding: 30px 65px 0 65px; }
				
			p { 
				font-size: 13px;
				line-height: 2;			
				color: #fff; 

				@media #{$down600} 	{ text-align: center; }
			}
		}
	}
}

#qualityCycleDots {
	position: relative;
	line-height: 700px;
	@media #{$down1600} { line-height: 650px; }
	@media #{$down1400} { line-height: 600px; }
	@media #{$down1024} { line-height: normal; }
	
	.slick-dots {
		position: relative;
		bottom: auto;
		display: inline-block;
		line-height: normal;
		vertical-align: middle;
		@media #{$down1024} { margin: -20px 0 20px 0; }
		@media #{$down600} 	{ margin: -30px 0 10px 0; }
		
		li {
			display: block;
			width: auto;
			height: auto;
			padding: 7px 0;
			text-align: center;
			@media #{$down1024} { display: inline-block; }
			
			&:hover button { color: #fff; }
			
			&.slick-active button { 
				color: $color2;
				
				&:after { 
					width: 100%;					
				}
			}
		}
		
		button {
			position: relative;
			display: inline-block;
			padding-right: 20px;
			font: 70px/1 $font1bold;
			color: rgba(#fff, 0.2);
			width: auto;
			height: auto;
			transition: color 300ms;
			@media #{$down1400} { font-size: 60px; }
			@media #{$down1200} { padding-right: 10px; font-size: 50px; }
			@media #{$down1024} { padding: 0 5px; }
			@media #{$down768} 	{ font-size: 40px; }
			@media #{$down600} 	{ font-size: 30px; padding: 0; }
			
			&::before { display: none; }
			
			&::after { 
				position: absolute;
				bottom: 10px;
				left: 10px;
				width: 0px;
				height: 2px;
				content: '';
				background: $color2;
				transition: width 300ms;
				@media #{$down1024} { left: 0; bottom: 5px; }
				@media #{$down600} 	{ left: 0; bottom: 0; }
			}
		}
	}
}

#compareProducts {
	text-align: left;

	&::before {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100px;
		content: '';
		background: #fff;
		@media #{$down1400} { height: 80px; }
		@media #{$down1200} { height: 60px; }
		@media #{$down600} 	{ display: none; }
	}

	> div:first-child {
        position: relative;
        height: 750px;
		z-index: 1;
		margin: 100px 0 0 0;
		@media #{$down1600}	{ height: 700px; }
        @media #{$down1400}	{ height: 620px; margin-top: 80px; }
		@media #{$down1200}	{ height: 550px; margin-top: 60px; }
		@media #{$down1024}	{ height: 500px; margin-top: 40px; }
		@media #{$down768}	{ height: 450px; margin-top: 0; }
		@media #{$down600}	{ height: auto; }

		h1 {
			position: absolute;
			bottom: 94%;
			left: -200px;
			color: rgba(#fff, 0.1);
			z-index: -1;
			@media #{$down1400} { left: -150px; }
			@media #{$down1200} { left: -100px; }
			@media #{$down768} 	{ left: -70px; bottom: 95%; }
			@media #{$down600} 	{
				position: static;
				left: auto;
				bottom: auto;
				padding: 0 20px;
			}
		}

		.pic {
			width: 100%;
			height: 100%;
			background: url('../../assets/images/content/innovation.jpg') no-repeat center top;
			background-size: cover;
			@media #{$down600}	{ height: 300px; margin-top: -20px; }
			@media #{$down500} 	{ height: 250px; }
		}
	}

    > div:last-child {
		min-height: 750px;
		line-height: 750px;
		@media #{$down1600}	{ min-height: 700px; line-height: 700px; }
		@media #{$down1400}	{ min-height: 620px; line-height: 620px; }
		@media #{$down1200}	{ min-height: 550px; line-height: 550px; }
		@media #{$down1024}	{ min-height: 500px; line-height: 500px; }
		@media #{$down768} 	{ min-height: 450px; line-height: 450px; }
		@media #{$down600} 	{ min-height: 0; line-height: normal; padding: 0 20px; }
		@media #{$down500} 	{ padding: 0 20px; }

    	.centerize {
			position: relative;
			margin-top: 100px;
			z-index: 1;
			@media #{$up600}   	{ padding-right: 30px; padding-left: 30px; }
			@media #{$up1024}   { padding-left: 22%; }
    		@media #{$up1200} 	{ padding-left: 26%; }
			@media #{$down600} 	{
				display: block;
				padding: 40px 0 60px 0;
				margin: 0;
				br { display: none; }
			}

			h2 {
				line-height: 1.4;
				color: #fff;
				margin-bottom: 30px;
				@media #{$down600} 	{ max-width: 350px; margin-bottom: 20px;}
			}
		}
	}
}